import React, { FC } from 'react';
import styled from 'styled-components';

import CircleTickInverted from 'assets/icons/payments/circle-tick-inverted.svg';
import theme from 'utils/theme';
import { Text } from 'components';

interface EmailInputProps {
  email: string;
  label: string;
}

const Container = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;
  background-color: #f9f9f9;
  padding: 0.625rem 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.75rem;
  align-items: center;
`;

const InputContainer = styled.div`
  display: grid;
  row-gap: 0.25rem;
`;

const Label = styled(Text).attrs({
  type: 'bodyS700',
})`
  span {
    color: ${({ theme }) => theme.colors.red100};
  }
`;

const EmailInput: FC<EmailInputProps> = ({ email, label }) => (
  <Container>
    <CircleTickInverted color={theme.colors.green} width={24} height={24} />
    <InputContainer>
      <Label>
        {label} <span>*</span>
      </Label>
      <Text type="bodyM" color="dark80">
        {email}
      </Text>
    </InputContainer>
  </Container>
);

export default EmailInput;
