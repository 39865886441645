import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { use100vh } from 'react-div-100vh';

import { Seo } from 'components';
import PaymentDialog from './components/OldPaymentDialog';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { HyrosApi } from 'apis/hyrosApi';
import { config } from '../../../config';
import { Events } from 'utils/events';
import { useCheckoutData, usePageView } from 'utils/hooks';
import { getLocalisedProduct } from 'utils/localization';
import { handleError } from 'utils/error';
import { Quiz } from 'types/quiz';
import Tracking from 'utils/tracking';

const OldPayments: FC = () => {
  const { code, selected_plan, user, geolocation, quiz_answers } = useSelector(
    (state: AppState) => state.user,
  );
  const { goToRegister, navigateBack, goToDiscount } = useRouter();

  const { data } = useCheckoutData();
  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;

  // usePageView({
  //   clientCode: code,
  //   country: geolocation?.iso_country,
  //   state: geolocation?.iso_state,
  //   gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  //   email: user?.email,
  // });

  const height = use100vh() ?? 0;

  useEffect(() => {
    if (!code) {
      return;
    }
    Tracking.logEvent({
      event: 'payment-window-clicks',
      funnel: quiz,
      widget: 'payment-window-turned-on',
      product: createProductNameString(localisedProducts),
    });

    Tracking.logEvent({
      event: 'email_left',
      client_code: code,
    });
  }, [code]);

  const localisedProducts = getLocalisedProduct(selected_plan);

  const calculateFullPrice = products => {
    let fullPrice = 0;

    for (let i = 0; i < products.length; i++) {
      fullPrice = fullPrice + Number(products[i].finalPrice);
    }

    return fullPrice.toString();
  };

  const createProductNameString = products => {
    const prodNames = products.map(prod =>
      prod.name.replace('Nova Health', '').trim(),
    );
    return prodNames.join(', ');
  };

  const createProductSkyString = products => {
    const prodNames = products.map(prod => prod.sku);
    return prodNames.join(', ');
  };

  const createHyrosOrder = async ({ orderId }: { orderId: number }) => {
    try {
      if (config?.ENV === 'development') {
        return;
      }
      await HyrosApi.createOrder({
        orderId: orderId ?? 0,
        email: user?.email ?? '',
        productName: createProductNameString(localisedProducts),
        productSky: createProductSkyString(localisedProducts),
        orderPrice: parseFloat(calculateFullPrice(localisedProducts)),
      });
    } catch (e) {
      handleError(e);
    }
  };

  const handleSuccess = async (data: any) => {
    try {
      const orderId = String(data?.order_id ?? '');
      const eventData = {
        code: code ?? '',
        orderId,
        data: {
          currencyCode: localisedProducts[0]?.currency,
          paymentMethod: '',
          transactionId: orderId,
          transactionTotal: parseFloat(calculateFullPrice(localisedProducts)),
          transactionAffiliation: '',
          transactionProducts: [
            {
              sku: createProductSkyString(localisedProducts),
              name: createProductNameString(localisedProducts),
              category: 'Subscription',
              price: parseFloat(calculateFullPrice(localisedProducts)),
              quantity: 1,
            },
          ],
        },
      };

      Events.paymentSuccessful(eventData);

      createHyrosOrder({ orderId: Number(orderId) });
    } catch (e) {
      handleError(e);
    }

    goToRegister();
  };

  const handleOnClose = () => {
    Tracking.logEvent({
      event: 'payment-window-clicks',
      funnel: quiz,
      widget: 'payment-window-turned-off',
      product: createProductNameString(localisedProducts),
    });
    if (data?.discountAvailable) {
      return goToDiscount();
    }
    navigateBack();
  };

  // Fixes screen jumping when entering credit card number
  useEffect(() => {
    const bodyWrapper = document.getElementById('body-wrapper');
    if (!bodyWrapper) {
      return;
    }

    bodyWrapper.style.height = `${height}px`;

    return () => {
      bodyWrapper.style.height = '';
    };
  }, [height]);

  return (
    <>
      <Seo />
      <PaymentDialog
        selectedProduct={selected_plan}
        isOpen={true}
        onRequestClose={handleOnClose}
        onSuccess={handleSuccess}
        orderSummarySubItems={data?.orderSummarySubItems}
      />
    </>
  );
};

export default OldPayments;
