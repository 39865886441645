import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import CircleChecked from 'assets/icons/payments/circle-checked.svg';
import CircleUnchecked from 'assets/icons/payments/circle-unchecked.svg';
import theme from 'utils/theme';

interface PaymentStepsProps {
  firstStepTitle: string;
  secondStepTitle: string;
  thirdStepTitle: string;
}

interface DotDividerProps {
  hasOpacity?: boolean;
}

const Container = styled.div`
  display: flex;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DotTitle = styled(Text).attrs({
  type: 'bodyS',
})`
  text-align: center;
  font-size: 0.625rem;
  margin-top: 0.25rem;
`;

const DotDividerContainer = styled.div`
  flex: 1;
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
`;

const DotDivider = styled.div<DotDividerProps>`
  flex: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.green};
  opacity: ${({ hasOpacity }) => (hasOpacity ? 0.25 : 1)};
`;

const PaymentSteps: FC<PaymentStepsProps & HTMLAttributes<HTMLDivElement>> = ({
  firstStepTitle,
  secondStepTitle,
  thirdStepTitle,
  ...props
}) => {
  const renderDot = (dot?: string) => {
    switch (dot) {
      case 'tick':
        return <CircleTick fill={theme.colors.green} width={24} height={24} />;
      case 'checked':
        return (
          <CircleChecked fill={theme.colors.green} width={24} height={24} />
        );
      default:
        return (
          <CircleUnchecked fill={theme.colors.green} width={24} height={24} />
        );
    }
  };

  return (
    <Container {...props}>
      <StepContainer>
        {renderDot('tick')}
        <DotTitle>{firstStepTitle}</DotTitle>
      </StepContainer>
      <DotDividerContainer>
        <DotDivider />
      </DotDividerContainer>
      <StepContainer>
        {renderDot('checked')}
        <DotTitle>{secondStepTitle}</DotTitle>
      </StepContainer>
      <DotDividerContainer>
        <DotDivider hasOpacity />
      </DotDividerContainer>
      <StepContainer>
        {renderDot()}
        <DotTitle>{thirdStepTitle}</DotTitle>
      </StepContainer>
    </Container>
  );
};

export default PaymentSteps;
