import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { OrderSummarySubItem } from 'types/checkout';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';

interface PaymentDialogOrderSummaryProps {
  selectedProduct: Product | null;
  subItems?: OrderSummarySubItem[];
}

const Container = styled.div`
  padding: 1rem;
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;
  background-color: #f9f9f9;
`;

const OrderSummaryItemContainer = styled.div<{ paddingBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ paddingBottom }) => (!paddingBottom ? 0 : 0.75)}rem;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
  margin: 1rem 0 1rem 0;
`;

const PaymentDialogOrderSummary: FC<
  PaymentDialogOrderSummaryProps & HTMLAttributes<HTMLDivElement>
> = ({ selectedProduct, subItems, ...props }) => {
  if (!selectedProduct) {
    return null;
  }

  const product = getLocalisedProduct(selectedProduct);

  return (
    <Container {...props}>
      <OrderSummaryItemContainer paddingBottom>
        <Text type="bodyM">{selectedProduct?.name}</Text>
        <Text type="bodyM">
          {product.currency}
          {product.regularPrice}
        </Text>
      </OrderSummaryItemContainer>
      <OrderSummaryItemContainer paddingBottom>
        <Text type="bodyM700" color="red80">
          Discount (-{product.savings}%)
        </Text>
        <Text type="bodyM" color="red80">
          -{product.currency}
          {product.discount}
        </Text>
      </OrderSummaryItemContainer>
      {(subItems || []).map((subItem, index) => (
        <OrderSummaryItemContainer key={index}>
          <Text type="bodyM">{subItem.title}</Text>
          {subItem.value && (
            <Text type="bodyM">
              <span dangerouslySetInnerHTML={{ __html: subItem.value }} />
            </Text>
          )}
        </OrderSummaryItemContainer>
      ))}
      <Divider />
      <OrderSummaryItemContainer>
        <Text type="body700">Total:</Text>
        <Text type="h4700">
          {product.currency}
          {product.finalPrice}
        </Text>
      </OrderSummaryItemContainer>
    </Container>
  );
};

export default PaymentDialogOrderSummary;
